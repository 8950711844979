var meterController = {


    info() {
        var readingMethodSingleId = $('#singleMethod');
        var readingMethodCompoundId = $('#compoundMethod');
        var readingMethods = [];

        readingMethods[1] = 'Neptune';
        readingMethods[2] = 'Sensus';
        readingMethods[3] = 'Connect';
        readingMethods[4] = 'Beacon';
        readingMethods[5] = 'OMR';

        $('.meter-info').hide();  // hide all info boxes

        $('.' + readingMethods[readingMethodSingleId.val()] + '-info-single').fadeIn(); // show the preselected value's info boxes

        $('.' + readingMethods[readingMethodCompoundId.val()] + '-info-compound').fadeIn(); // show the preselected value's info boxes

        readingMethodSingleId.change(function(){  // on select change
            $('.meter-info').hide();    // hide all info boxes

            $('.' + readingMethods[readingMethodSingleId.val()] + '-info-single').fadeIn();  // show the selected value's info boxes
        });

        $('.' + readingMethods[readingMethodCompoundId.val()] + '-info-compound').fadeIn(); // show the preselected value's info boxes

        readingMethodCompoundId.change(function(){  // on select change
            $('.meter-info').hide();    // hide all info boxes

            $('.' + readingMethods[readingMethodCompoundId.val()] + '-info-compound').fadeIn();  // show the selected value's info boxes
        });
    }
};

module.exports = meterController;
export default meterController;
var designatedPaymentHelper = {

    amount: 0,
    available: 0,
    container:'',
    totalContainer:'',
    availableContainer:'',
    fields:'',
    form:'',


    init(form)
    {
        this.form = form;
        this.container = form.find('.designated_payment_container');
        this.container.show();
        this.amount = parseFloat(form.find('input[name=amount]').val()).toFixed(2);
        this.totalContainer = this.container.find('.total');
        this.totalContainer.html('$' + this.amount);
        this.availableContainer = this.container.find('.available');
        this.availableContainer.html('$' + this.amount).data('amount', this.amount);
        this.fields = this.container.find('.designated_field');
        this.initDesignatedFields();
        this.toggleSubmit();
        this.form.find('.designate_payments').click(function(){
            designatedPaymentHelper.destroy();
        })
        this.form.find('input[name=amount]').on('input',function(){
            designatedPaymentHelper.recalculate();
        });
    },

    initDesignatedFields()
    {
        this.getAvailable();
        this.fields.on('input', function(){designatedPaymentHelper.getAvailable()});

    },

    showDesignatedError(message)
    {
        alert(message);
    },

    getAvailable()
    {
        this.available = this.amount;
        this.fields.each(function(){
            if($(this).val() > 0)
            {
                designatedPaymentHelper.available -= parseFloat($(this).val()).toFixed(2);
            }

            if(designatedPaymentHelper.available < 0){
                designatedPaymentHelper.showDesignatedError('Insufficient Payment');
            }
        });

        this.available = parseFloat(this.available).toFixed(2);

        this.availableContainer.html('$' + this.available).data('amount', this.available);
        this.toggleSubmit();

    },

    destroy()
    {
        this.form.find('input[type="submit"]').prop('disabled', false);
    },

    toggleSubmit()
    {
        if(this.available != 0)
        {
            this.form.find('input[type="submit"]').prop('disabled', true);
        } else {
            this.form.find('input[type="submit"]').prop('disabled', false);
        }
    },

    recalculate()
    {
        this.amount = parseFloat(this.form.find('input[name=amount]').val()).toFixed(2);
        this.totalContainer.html('$' + this.amount);
        this.getAvailable();
    }

};

module.exports = designatedPaymentHelper;
export default designatedPaymentHelper;
import switcher from '../flow/forms/switcher';

var report = {

    advanced(){
        switcher.init('select#transactionType');
    },

    lockoff(){

        report.lockableTable = $('#lockable-table');
        report.exemptTable = $('#exempt-table');

        report.exemptionForm();
        report.removeExemptionButtons();
        $('.close-alert').on('click', function(){
            $(this).closest('.modal').hide();
        });
    },

    exemptionForm(){
        $(document).on('click', 'button.lock-off-exempt',function(){
            var accountId = $(this).data('account');

            var button = $(this);

            $('#lock-off-exemption-form').show().find("input[name=account_id]").val(accountId);

            $('#lock-off-exemption-form form').submit(function(e){
                e.preventDefault();
                var $this = $(this);
                var action = $this.attr('action');
                var method = $this.attr('method');
                $.ajax({
                    url: action,
                    type: method,
                    data: {account_id: accountId, until_date: $this.find('input[name=until_date]').val()}
                }).done(function(msg) {
                    $this.closest('.modal').hide();
                    $this.find("input[name=account_id]").val('');
                    report.moveRowToExempt(button.closest('tr'), msg);
                });

            });
        });
    },

    removeExemptionButtons(){
        $(document).on('click', '.lock-off-remove-exemption',function(event){
            event.stopPropagation();
            var $this = $(this);
            var row = $this.closest('tr');
            var accountId = $this.data('account');
            $.ajax({
                url: '/account/remove/lockoff/exemption',
                type: 'POST',
                data: {account_id: accountId}
            }).done(function(msg) {
                report.moveRowToLockable(row);
            });
        });
    },

    moveRowToLockable(row){
        row.find('td').eq(5).remove();
        row.find('td').eq(5).find('button').removeClass('lock-off-remove-exemption').unbind().addClass('lock-off-exempt').text('Exempt');
        row.appendTo(report.lockableTable);
    },

    moveRowToExempt(row, date){
        report.exemptTable.find('.no-results').remove();
        row.find('td').eq(5).find('button')
            .removeClass('lock-off-exempt').unbind().addClass('lock-off-remove-exemption').text('Remove Exemption')
            .closest('td').before('<td>' + date + '</td>');
        row.appendTo(report.exemptTable);
    }

};

module.exports = report;
export default report;

var achController = {


    index() {
        var profileIds = $('.eligible');
        var paymentTotals = $('#paymentTotals');
        var paymentCount = $('#paymentCount');
        var deselectAll = $('#deselectAll');

        deselectAll.click(function(){
            profileIds.removeAttr('checked');

            paymentCount.html(0);
            paymentTotals.html(0);
        });

        profileIds.change(function(){
            var previousTotal = parseFloat(paymentTotals.html());
            var previousCount = parseInt(paymentCount.html());

            if($(this).attr("checked"))
            {
                //call the function to be fired
                //when your box changes from
                //unchecked to checked
                var newCount = previousCount +1;
                var newTotal = (previousTotal + parseFloat($(this).attr("data-payment-amount"))).toFixed(2);

                paymentCount.html(newCount);
                paymentTotals.html(newTotal);
            }
            else
            {
                //call the function to be fired
                //when your box changes from
                //checked to unchecked
                var newCount = previousCount -1;
                var newTotal = (previousTotal - parseFloat($(this).attr("data-payment-amount"))).toFixed(2);

                paymentCount.html(newCount);
                paymentTotals.html(newTotal);
            }
        });
    },

    profileCreate()
    {
        var numberField = $('.number-field');
        var alphaField = $('.alpha-field');

        numberField.keydown(function( event ) {
            achController.numericOnly(event);
        });

        alphaField.keydown(function( event ) {
            achController.alphaOnly(event);
        });
    },

    profileEdit()
    {
        var numberField = $('.number-field');
        var alphaField = $('.alpha-field');

        numberField.keydown(function( event ) {
            achController.numericOnly(event);
        });

        alphaField.keydown(function( event ) {
            achController.alphaOnly(event);
        });
    },

    alphaOnly(event) {
        var keyCode = event.keyCode;

        if((keyCode >= 65 && keyCode <= 90) || keyCode == 8 || keyCode == 32 || keyCode == 37 || keyCode == 39 || keyCode == 46)
        {
            return;
        }

        event.preventDefault();
    },

    numericOnly(event) {
        var keyCode = event.keyCode;
        var key = event.key;


        // allow left-right arrows, backspace and delete
        if(keyCode == 37|| keyCode == 39 || keyCode == 46 || keyCode == 8)
        {
            return;
        }

        // prevent special characters from number keys
        if(key == "!" || key == "@" || key == "#" || key == "$" || key == "%" || key == "^" || key == "&" || key == "*" || key == "(" || key == ")")
        {
            event.preventDefault();
        }

        // prevent anything but number keys
        if((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105 ))
        {
            event.preventDefault();
        }
    }
};

module.exports = achController;
export default achController;
var auth = {

    /**
     * Login route.
     */
    login() {
        console.log('js on login');
    },

    /**
     * Register route.
     */
    register() {
        console.log('js on register');
    }

};

module.exports = auth;

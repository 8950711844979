var billingRoundController = {

    index() {
        var formSelect = $('.order-by-select');

        formSelect.change(function()
        {
            var roundId = $(this).data('select-round-id');

            var selectValue = this.value;

            $('.print-mailers-form-' + roundId).each(function() {

                if(!$(this).hasClass('hidden'))
                {
                    $(this).addClass('hidden')
                }
            });

            $('#order-by-' + selectValue + '-container-' + roundId).removeClass('hidden');
        });
    },
    show() {
        var formSelect = $('.order-by-select');

        formSelect.change(function()
        {
            var roundId = $(this).data('select-round-id');

            var selectValue = this.value;

            $('.print-mailers-form-' + roundId).each(function() {

                if(!$(this).hasClass('hidden'))
                {
                    $(this).addClass('hidden')
                }
            });

            $('#order-by-' + selectValue + '-container-' + roundId).removeClass('hidden');
        });
    }

};

module.exports = billingRoundController;
export default billingRoundController;
import noteModel from '../flow/accounts/notes/note';

var accountController = {

    show(params) {
        noteModel.init(params.accountId);

        $('a.lock-off').click(function(event){
            event.preventDefault();
            $('#lock-off-modal').show();
        });

        $('a.remove-lock-off').click(function(event){
            event.preventDefault();
            let service = $(this).data('service');
            console.log(service);
            $('#lock-off-remove-modal-'+ service).show();
        });

        $('a.cancel-account').click(function(event){
            event.preventDefault();
            $('#cancel-modal').show();
        });

        $('#community-toggle').click(function(event){
            $('#community-modal').show();
        });


        $('.lock-off-remove-exemption').click(function(event){
            var accountId = $(this).data('account');

            $.ajax({
                url: '/account/remove/lockoff/exemption',
                type: 'POST',
                data: {account_id: accountId}
            }).done(function(msg) {
                location.reload();
            });
        });



        $('.unlink-user').click(function(){

            var $this = $(this);

            var userId = $this.data('user_id');

            $.ajax({
                url: '/' + laroute.by_name('account_user_unlink'),
                type: 'POST',
                data: {account_id: params.accountId, user_id:userId }
            }).done(function() {
                $this.remove();
            });
        });

        $('.delete-user').click(function(){

            var $this = $(this);

            var userId = $this.data('user_id');

            $.ajax({
                url: '/' + laroute.by_name('account_user_delete'),
                type: 'DELETE',
                data: {account_id: params.accountId, user_id:userId }
            }).done(function() {
                $this.parent().remove();
                $('#profile-toggle').remove();
            });
        });

        $('.close-alert').click(function(){
            var $this = $(this);
            $this.closest('.modal').hide();
        });

        $('#profile-toggle').click(function(){
            $('#user-profile-details').show();
        });
    }

};

module.exports = accountController;
export default accountController;

var switcher = {

    init(element) {
        this.selectBox = $(element);
        this.switchIt();
        this.selectBox.on('change', this.switchIt)
    },

    switchIt() {
        var selected = switcher.selectBox.val();
        $('.type-filter').hide();
        $('.type-filter select').val('');
        $('.type-filter.filter-'+selected.toLowerCase()).show();

    }
};

module.exports = switcher;
export default switcher;
var paymentMethodProfile = {

    init(element) {

        this.checkboxes = $(element);
        this.checkboxes.on('click',this.toggleRecurring);

    },

    toggleRecurring(){

        var currentCheckbox = $(this);
        var checked = currentCheckbox.prop('checked');

        $.ajax({
            url: '/resources/payments/recurring/update/'+ currentCheckbox.data('profile_id'),
            type: 'post',
            data: {recurring:checked}
        }).done(function(msg) {
            paymentMethodProfile.checkboxes.prop('checked', false);
            currentCheckbox.prop('checked', checked);
        });
    }

};

module.exports = paymentMethodProfile;
export default paymentMethodProfile;

var main = {

    init() {
        $(document).ready($.proxy(this.onDocumentReady, this));
        $(document).on('change', $.proxy(this.onDocumentChange, this));
        $('window').load($.proxy(this.onWindowLoad, this));

        return this;
    },

    /**
     * Callback event for when the window is loaded
     * in the browser.
     */
    onWindowLoad() {
        $("body").removeClass("preload");
    },

    /**
     * Callback event for when the ready event is fired
     * on the the dom.
     */
    onDocumentReady() {
        $("#find-company").focus(this.focusFindCompany);
        $('.mobile-quick').on('click', this.toggleQuickControls);
    },

    onDocumentChange() {
        $('.show-hide .control').each(this.controlToggle);
    },

    /**
     * Toggle control elements.
     *
     * @param $control
     */
    controlToggle() {
        var $control = $(this);
        var $container = $control.closest('.show-hide');

        if ($control.val() == 1) {
            $container.find('.content').show();
        } else {
            $container.find('.content').hide();
        }
    },

    /**
     * On focus on the find company element, shift the background
     * position downward.
     */
    focusFindCompany() {
        $(".icon-search").css('background-position', '0px -32px');
    },

    /**
     * Display a dropdown option of quick control links in mobile
     * for easy navigation.
     */
    toggleQuickControls() {
        $(this).parents('.quick-controls').children('ul').toggle();
    }

}.init();

var adjustmentController = {

    create() {
        var formSelect = $('#form-select');

        formSelect.change(function()
        {

            console.log(this.value);
            var selectValue = this.value;

            $('.adjustment-form').each(function() {

                if(!$(this).hasClass('hidden'))
                {
                    $(this).addClass('hidden')
                }
            });

            $('#' + selectValue).removeClass('hidden');
        });

        $('#installment-select').change(function()
        {
            var selectValue = this.value;

            $('.multiple-form-container').each(function() {

                if(!$(this).hasClass('hidden'))
                {
                    $(this).addClass('hidden')
                }
            });

            $('#' + selectValue).removeClass('hidden');
        });
    }

};

module.exports = adjustmentController;
export default adjustmentController;
import paymentMethodProfileModel from '../flow/payment/profiles/methods/paymentMethodProfile';
import designatedPaymentHelper from '../flow/payment/forms/designatedPaymentHelper';

var paymentController = {

    settings() {
        paymentMethodProfileModel.init('.recurring-check-box');
    },

    bulkTimer: null,

    create() {

        paymentController.forms = $('.dynamicForm');
        paymentController.method = $('#method');
        $(document).on('change', '.designate_payments', function(){
            if($(this).is(':checked'))
            {
                designatedPaymentHelper.init($(this).closest('form'));
            } else {
                $(this).siblings('.designated_payment_container').hide();
            }
        });

        paymentController.calculateInitialFeeAmount();

        var numberField = $('.number-field');

        numberField.keydown(function( event ) {
            paymentController.numericOnly(event);
        });

        numberField.keyup(function( event ) {
            paymentController.calculateFeeAmount(event);
        });

        paymentController.showCorrectForm();

        paymentController.method.change(paymentController.showCorrectForm);
    },

    showCorrectForm()
    {
        paymentController.position = paymentController.method.find('option:selected').index();
        paymentController.forms.hide();
        paymentController.currentForm = paymentController.forms.eq(paymentController.position);
        paymentController.currentForm.find('form input[type=\'submit\']').click(function(e){
            e.preventDefault();
            var payment = parseFloat($(this).closest('form').find('#amount').val().replace(',', ''));
            if(payment > parseFloat($('.next-payment-amount').html().replace(',', '')))
            {
                if(confirm('You are submitting a payment greater than what is due. Continue?')) { $(this).closest('form').submit(); }
            } else {
                $(this).closest('form').submit();
            }
        });

        paymentController.currentForm.show();
        if("Cash" == paymentController.method.find('option:selected').text())
        {
            paymentController.initCalculator();
        };
    },

    initCalculator()
    {
        paymentController.resultContainer = paymentController.currentForm.find('#change');
        paymentController.resultSpan = paymentController.resultContainer.find('#result');
        paymentController.tenderedInput = paymentController.currentForm.find('#tendered');
        paymentController.tenderedInput.parent().show();
        paymentController.amountInput = paymentController.currentForm.find('#amount');
        paymentController.tenderedInput.on('input', paymentController.calculateChange);
    },

    calculateChange()
    {
        var change = 0;

        if(parseFloat(paymentController.amountInput.val()) && parseFloat(paymentController.tenderedInput.val()))
        {
            change = parseFloat(paymentController.tenderedInput.val()) - parseFloat(paymentController.amountInput.val());
        }

        if(change > 0)
        {
            paymentController.resultContainer.show();

        } else {
            paymentController.resultContainer.hide();
        }

        paymentController.resultSpan.text(change.toFixed(2));
    },


    bulkCreate()
    {
        $(document).on('input change', '.account_number', function (){

            if(paymentController.bulkTimer != null) clearTimeout(paymentController.bulkTimer);
            var $this = $(this);
            var $counter = $(this).attr('data-counter');
            paymentController.bulkTimer = setTimeout(function() {
                paymentController.getBalanceByAccountNumber($this, $counter);
            },200);

        });
    },

    getBalanceByAccountNumber(element, counter)
    {
        if(element.val().length > 0)
        {
            $.ajax({
                url:      '/resources/account/balance',
                type: 'POST',
                data: {account_number: element.val()}
            }).done(function(msg){
                $('#balance-' + counter).html(msg.account_balance);
                $('#account-name-' + counter).html(msg.account_name);
            });
        }
        else
        {
            $('#balance-' + counter).html("No Account");
            $('#account-name-' + counter).html("No Account");
        }

        paymentController.bulkTimer = null;
    },

    calculateInitialFeeAmount()
    {
        var creditFee = parseFloat($('#cc_fee').val());
        var paymentAmount = parseFloat($('.credit-amount').val());

        var amount = creditFee + paymentAmount;

        amount = amount || 0;

        if(isNaN(amount))
        {
            amount = "0";
        }

        var formattedAmount = (Math.round(amount*100)/100).toFixed(2);

        $('.display-total').text('$' + formattedAmount);
    },

    calculateFeeAmount(event)
    {
        var creditFee = parseFloat($('#cc_fee').val());
        var paymentAmount = parseFloat($(event.target).val());

        var amount = creditFee + paymentAmount;

        amount = amount || 0;

        if(isNaN(amount))
        {
            amount = "0";
        }

        var formattedAmount = (Math.round(amount*100)/100).toFixed(2);

        $('.display-total').text('$' + formattedAmount);
    },

    numericOnly(event)
    {
        var keyCode = event.keyCode;
        var key = event.key;

        // allow left-right arrows, backspace and delete
        if(keyCode == 37|| keyCode == 39 || keyCode == 46 || keyCode == 8)
        {
            return;
        }

        // prevent special characters from number keys
        if(key == "!" || key == "@" || key == "#" || key == "$" || key == "%" || key == "^" || key == "&" || key == "*" || key == "(" || key == ")")
        {
            event.preventDefault();
        }

        // prevent anything but number keys
        if((keyCode < 48 || keyCode > 57) && (keyCode < 96 || (keyCode > 105 && keyCode != 190 && keyCode != 110)))
        {
            event.preventDefault();
        }

    }
};

module.exports = paymentController;
export default paymentController;
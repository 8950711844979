var note = {

    page: 1,

    init(accountId) {
        this.message = 'This is my test message';
        this.accountId = accountId;
        this.form = $('#note-form form');
        this.noteContainer = $('.account-notes .notes-container');
        this.widgetContainer = $('.account-notes');
        this.noteControl = $('.note-control');
        this.form.on('submit', note.submitNote);
        this.noteControl.on('click', note.toggleForm);
        this.getNotes();

    },

    toggleForm() {
        note.widgetContainer.toggleClass('form-shown');
    },

    getNotes()
    {
        $.ajax({
            url: '/resources/notes/'+ note.accountId + '?page='+note.page,
            type: 'get'
        }).done(function(msg) {
            note.noteContainer.append(msg);
            $('#more-notes').click(function(){
                note.loadMoreNotes();
                $(this).remove();
            });
        });
    },

    loadMoreNotes(){
        note.page++;
        this.getNotes();
    },

    submitNote(e) {
        e.preventDefault();
        var data = note.form.serialize();
        $.ajax({
            url: '/resources/notes',
            data: data,
            type: 'post'
        }).error(function(err){

            var errors = JSON.parse(err.responseText);

            if(errors.hasOwnProperty('title'))
            {
                alert(errors.title);
            }

            if(errors.hasOwnProperty('content'))
            {
                alert(errors.content);
            }

        }).done(function(msg) {
            note.noteContainer.prepend(msg);
            note.widgetContainer.removeClass('form-shown');
            note.widgetContainer.find('.empty-notice').remove();
            note.form[0].reset();
        });
    }

};

module.exports = note;
export default note;
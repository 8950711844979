var readingController = {

    bulkManual() {

        var numberField = $('.number-field');

        numberField.keydown(function( event ) {

            var meterId = $(this).attr('id');

            readingController.numericOnly(event, meterId);
        });

        numberField.keyup(function( event ) {

            var meterId = $(this).attr('id');

            if(readingController.numericOnly(event, meterId))
            {
                var currentRead = $("#meter-" + meterId + "-current").html();

                var newRead = $("#" + meterId).val();

                readingController.calculateElapsed(meterId, currentRead, newRead);
            }
        });
    },

    numericOnly(event)
    {
        var keyCode = event.keyCode;
        var key = event.key;

        // allow left-right arrows, backspace and delete
        if(keyCode == 37|| keyCode == 39 || keyCode == 46 || keyCode == 8)
        {
            return true;
        }

        // prevent special characters from number keys
        if(key == "!" || key == "@" || key == "#" || key == "$" || key == "%" || key == "^" || key == "&" || key == "*" || key == "(" || key == ")")
        {
            event.preventDefault();
            return false;
        }

        // prevent anything but number keys
        if((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105 ))
        {
            event.preventDefault();
            return false;
        }

        return true;
    },

    calculateElapsed(meterId, currentRead, newRead)
    {
        var calculatedElapsed = 0;

        if(newRead != 0){
            calculatedElapsed = newRead - currentRead;
        }

        $("#meter-" + meterId + "-elapsed").html(calculatedElapsed);

        readingController.calculateTotal();
    },

    calculateTotal()
    {
        var sum = 0;

        $('.elapsed').each(function() {
            sum += Number($(this).html());
        });

        $("#the-total").html(sum);
    }
};

module.exports = readingController;
export default readingController;
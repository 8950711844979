var CommunityConfigController = {

    configEdit() {

    var lateFeeCalculationMethodSelect = $('#late-fee-calculation-method-select');
    var lateFeeAllOutstandingSelect = $('#late-fee-all-outstanding-select');

    $('#late-fee-calculation-method-' + lateFeeCalculationMethodSelect.val() + '-info').removeClass('hidden');
    $('#late-fee-all-outstanding-' + lateFeeAllOutstandingSelect.val() + '-info').removeClass('hidden');

    lateFeeCalculationMethodSelect.on('change', function() {
        $('.late-fee-calculation-method-info').addClass('hidden');
        $('#late-fee-calculation-method-' + $(this).val() + '-info').removeClass('hidden');
    });

    lateFeeAllOutstandingSelect.on('change', function() {
        $('.late-fee-all-outstanding-info').addClass('hidden');
        $('#late-fee-all-outstanding-' + $(this).val() + '-info').removeClass('hidden');
    });

}

};

module.exports = CommunityConfigController;
export default CommunityConfigController;
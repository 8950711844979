var analyticsController = {

    // Charts For Payments

    payments() {
        analyticsController.renderTotalPaymentsChart();
    },

    renderTotalPaymentsChart() {
        $.ajax({
            url:      '/resources/payments/chart',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-total', {
                    labels: msg.labels,
                    series:
                    msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    }
                },

            analyticsController.renderAveragePaymentsChart());
        });
    },

    renderAveragePaymentsChart() {
        $.ajax({
            url:      '/resources/payments/average',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-average', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    }
                },

            analyticsController.renderTotalByMethodPaymentsChart());
        });
    },

    renderTotalByMethodPaymentsChart() {
        $.ajax({
            url:      '/resources/payments/bymethod',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-by-method', {
                    labels: msg.labels,
                    series:
                        msg.series
                    //, colors: ['#ffc107', '#303f9f']
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    },
                    chartPadding: {
                        right: 40
                    },
                    plugins: [
                        Chartist.plugins.legend({
                            legendNames: msg.legendNames
                        })
                    ]
                },

            analyticsController.renderTotalPaymentsByAccountTypeChart());
        });
    },

    renderTotalPaymentsByAccountTypeChart() {
        $.ajax({
            url:      '/resources/payments/totalbyaccounttype',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-total-account-type-chartist', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    },
                    plugins: [
                        Chartist.plugins.legend({
                            legendNames: msg.legendNames
                        })
                    ]
                },

            analyticsController.renderTotalPaymentsByBillingCycleChart());
        });
    },

    renderTotalPaymentsByBillingCycleChart() {
        $.ajax({
            url:      '/resources/payments/totalbybillingcycle',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-total-billing-cycle-chartist', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    },
                    plugins: [
                        Chartist.plugins.legend({
                            legendNames: msg.legendNames
                        })
                    ]
                },

            analyticsController.renderTotalAgedReceivablesChart());
        });
    },

    renderTotalPaymentsByRateGroupChart() {
        $.ajax({
            url:      '/resources/payments/totalbyrategroup',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-total-rate-groups-chartist', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            });
        });
    },


    // Charts For Charges

    charges() {

        analyticsController.renderTotalChargesChart();
    },

    renderTotalChargesChart() {
        $.ajax({
            url:      '/resources/charges/chart',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#charges-total', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    }
                },

            analyticsController.renderAverageChargesChart());
        });
    },

    renderAverageChargesChart() {
        $.ajax({
            url:      '/resources/charges/average',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#charges-average', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: false,
                    axisY: {
                    }
                },

            analyticsController.renderTotalChargesByAccountTypeChart());
        });
    },

    renderTotalChargesByAccountTypeChart() {
        $.ajax({
            url:      '/resources/charges/by/account/type',
            dataType: 'json'
        }).done(function (msg) {
            new Chartist.Line('#charges-total-by-account-type', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: true,
                    axisY: {
                    },
                    plugins: [
                        Chartist.plugins.legend({
                            legendNames: msg.legendNames
                        })]
                },

                analyticsController.renderTotalChargesByBillingCycleChart());
        });
    },

    renderTotalChargesByBillingCycleChart() {
        $.ajax({
            url:      '/resources/charges/by/cycle',
            dataType: 'json'
        }).done(function (msg) {
            new Chartist.Line('#charges-total-by-cycle', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: true,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })]
            });
        });
    },


    // Charts For Readings

    readings() {
        analyticsController.renderReadingChart();
    },

    renderReadingChart() {
        $.ajax({
            url:      '/resources/readings/chart',
            dataType: 'json'
        }).done(function (msg) {
            new Chartist.Line('#readings-total', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: true,
                axisY: {
                }
            },

            analyticsController.renderAverageReadingsChart());
        });
    },

    renderAverageReadingsChart() {
        $.ajax({
            url:      '/resources/readings/average',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#readings-average', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                }
            },

            analyticsController.renderTotalReadingsByAccountTypeChart());
        });
    },

    renderTotalReadingsByAccountTypeChart() {
        $.ajax({
            url:      '/resources/readings/totalbyaccounttype',
            dataType: 'json'
        }).done(function (msg) {
            new Chartist.Line('#readings-total-by-account-type', {
                    labels: msg.labels,
                    series:
                        msg.series
                }, {
                    height: 260,
                    low: 0,
                    showArea: true,
                    axisY: {
                    },
                    plugins: [
                        Chartist.plugins.legend({
                            legendNames: msg.legendNames
                        })]
                },

                analyticsController.renderTotalByMethodReadingsChart());
        });
    },

    renderTotalByMethodReadingsChart() {
        $.ajax({
            url:      '/resources/readings/bymethod',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#readings-by-method', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                chartPadding: {
                    right: 40
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderMatchHeight());
        });
    },


    // Charts For Aged Receivables

    agedReceivables() {
        analyticsController.renderAverageAgedReceivablesChart();
    },

    renderAverageAgedReceivablesChart() {
        $.ajax({
            url:      '/resources/aged/totalaverage',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Bar('#aged-receivables-total-average', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                axisY: {
                },
                stackBars: true,
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderTotalAgedReceivablesByBillingCycleChart());
        });
    },

    renderTotalAgedReceivablesByBillingCycleChart() {
        $.ajax({
            url:      '/resources/aged/totalbybillingcycle',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#aged-total-billing-cycle-chartist', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderTotalAgedReceivablesChart());
        });
    },

    renderTotalAgedReceivablesChart() {
        $.ajax({
            url:      '/resources/aged/chart',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Bar('#aged-receivables-total', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                axisY: {
                },
                stackBars: true,
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderMatchHeight());
        });
    },


    // Charts For Specific Billing Cycle

    cycleCharts(params) {

        analyticsController.renderBillingCyclePaymentsByMethodChart(params.id);
        analyticsController.checkoutRecentGenerations();
    },

    renderBillingCyclePaymentsByMethodChart(cycleId) {

        $.ajax({
            url:      '/resources/cycle/payments/by/methods/' + cycleId,
            dataType: 'json',
            data: {cycleId: cycleId}
        }).done(msg => {
            new Chartist.Line('#billing-cycle-payments-methods-chartist-' + cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderBillingCyclePaymentsAverageChart(cycleId));
        });
    },

    renderBillingCyclePaymentsAverageChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/payments/average/' + cycleId,
            dataType: 'json',
            data: {cycleId: cycleId}
        }).done(msg => {
            new Chartist.Line('#billing-cycle-payments-average-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 400,
                low: 0,
                showArea: false,
                axisY: {
                }
            },
                analyticsController.renderBillingCyclePaymentsByAccountTypesChart(cycleId));
        });
    },

    renderBillingCyclePaymentsByAccountTypesChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/payments/by/account/type/' + cycleId,
            dataType: 'json',
            data: {cycleId: cycleId}
        }).done(msg => {
            new Chartist.Line('#billing-cycle-payments-account-types-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderBillingCycleAgedReceivablesChart(cycleId));
        });
    },

    renderBillingCycleAgedReceivablesChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/aged/receivables/' + cycleId,
            dataType: 'json'
        }).done(msg => {
            new Chartist.Bar('#billing-cycle-aged-receivables-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                axisY: {
                },
                stackBars: true,
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderBillingCycleTotalReadingsChart(cycleId));
        });
    },

    renderBillingCycleTotalReadingsChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/readings/total/' + cycleId,
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#billing-cycle-readings-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                }
            },
                analyticsController.renderBillingCycleReadingsByReadingMethodChart(cycleId));
        });
    },

    renderBillingCycleReadingsByReadingMethodChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/readings/by/method/' + cycleId,
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#billing-cycle-reading-methods-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderBillingCycleReadingsByRouteChart(cycleId));
        });
    },

    renderBillingCycleReadingsByRouteChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/readings/by/route/' + cycleId,
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#billing-cycle-reading-routes-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderBillingCycleReadingsByAccountTypeChart(cycleId));
        });
    },

    renderBillingCycleReadingsByAccountTypeChart(cycleId) {
        $.ajax({
            url:      '/resources/cycle/readings/by/account/type/' + cycleId,
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#billing-cycle-reading-account-types-chartist-' + msg.cycleId, {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 300,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                    })
                ]
            },
                analyticsController.renderMatchHeight());
        });
    },

    renderMatchHeight() {
        $('.col-1-2.dash-block').matchHeight();
        $('.dash-block .title').matchHeight();
    },

    checkoutRecentGenerations() {

        $(document).on("click", "#you-sure", function (e) {
            e.preventDefault();
            let button = confirm("Are you sure? A billing round was generated for this cycl within the last 10 days");
            if (button != true) {
                return;
            }
            $('.recent-gen-hide').show()
        });
    }
};

module.exports = analyticsController;
export default analyticsController;
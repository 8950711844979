import authController from './auth';
import dashboardController from './dashboard';
import routeController from './route';
import billingRateGroupController from './billingRateGroup';
import accountController from './account';
import paymentController from './payment';
import reportsController from './report';
import meterController from './meter';
import achController from './ach';
import analyticsController from './analytics';
import readingController from './reading';
import adjustmentController from './adjustment';
import billingCycleController from './billingCycle';
import billingRoundController from './billingRound';
import CommunityConfigController from './communityConfig';


var app = {

    /**
     * Route name / function calls on that route. The route names
     * match the route names defined in the laravel routes.php.
     * When you add a route, run php artisan generate:laroute
     */
    routes: {
        'dashboard': dashboardController.home,
        'login': authController.login,
        'register_path': authController.register,
        'route_add_meters': routeController.addMeter,
        'route_edit': routeController.edit,
        'route_resequence': routeController.resequence,
        'billing_rate_group_path': billingRateGroupController.create,
        'billing_rate_group_edit_path': billingRateGroupController.edit,
        'account_show': accountController.show,
        'payments_account_settings': paymentController.settings,
        'payments_path_create': paymentController.create,
        'payments_bulk_create': paymentController.bulkCreate,
        'reports_advanced_search': reportsController.advanced,
        'reports_lockoff': reportsController.lockoff,
        'meter_create': meterController.info,
        'meter_edit': meterController.info,
        'payments_ach_profile_index': achController.index,
        'payments_ach_profile_create': achController.profileCreate,
        'payments_ach_profile_edit': achController.profileEdit,
        'analytics_payments': analyticsController.payments,
        'analytics_charges': analyticsController.charges,
        'analytics_readings': analyticsController.readings,
        'analytics_aged_receivables': analyticsController.agedReceivables,
        'billing_cycle_show': analyticsController.cycleCharts,
        'reading_manual_route_create': readingController.bulkManual,
        'adjustment_create': adjustmentController.create,
        'billing_cycle_config': billingCycleController.configEdit,
        'billing_rounds_index': billingRoundController.index,
        'billing_rounds_show': billingRoundController.show,
        'community_config': CommunityConfigController.configEdit,
    },

    /**
     * Initialize.
     */
    init() {
        this.initRoutes();
        return this;
    },

    /**
     * Initialize routes.
     */
    initRoutes() {
        var routes = {};

        Object.keys(this.routes).forEach((routeName) => {
            routes[laroute.by_name(routeName)] = this.routes[routeName];
        });


        this.routes = routes;
    },

    /**
     * Run before router fires.
     */
    before() {
        this.runJquery();
        this.setupClickToggle();
    },

    /**
     * Add click listener events to all elements that contain
     * a data-clicktoggle attribute. Toggle the element with the id
     * that the data-clicktoggle attribute contains.
     */
    setupClickToggle() {
        $('[data-clicktoggle]').each((index, el) => {
            var id = $(el).data('clicktoggle');

            $(el).on('click', () => {
                $(`#${id}`).toggle();
            });
        });
    },

    /**
     * Clean this up.
     */
    runJquery() {
        $("body").removeClass("preload");

        $("#find-company").focus(() => {
            $(".icon-search").css('background-position', '0px -32px');
        });

        $("#find-company").blur(() => {
            $(".icon-search").css('background-position', '0px 0px');
        });

        $('.tooltip').tooltipster({
            theme: 'tooltipster-noir'
        });

        $('.js-menu-trigger,.js-menu-screen').on('click touchstart', () => {
            $('.js-menu,.js-menu-screen').toggleClass('toggle');
            event.preventDefault();
        });

        $(".go").click(() => {
            $("#quick-link").slideToggle("slow", () => {
                var txt = $("go").is(':visible') ? 'Link another account.' : 'Hide quick link.';
                $(".go").text(txt);
            });
        });

        $(".show-controls").click(() => {
            $(this).siblings(".quick-link").slideToggle("fast");
        });

        $(".close-alert").click(() => {
            $(this).parent().fadeOut("slow", () => {
                $(this).remove();
            });
        });

        $('#company-selector').change(() => {
            $('#location-filter-form').submit();
        });


        // Select the submit buttons of forms with data-confirm attribute

        $(document).on('click', "form[data-confirm] input[type='submit'], form[data-confirm] button[type='submit']", function (e) {

            // Prevent the form to be submitted
            e.preventDefault();

            var button = $(this); // Get the button
            var form = button.closest('form'); // Get the related form
            var msg = form.data('confirm'); // Get the confirm message

            if (confirm(msg)) form.submit(); // If the user confirm, submit the form

        });

        $(document).on('click', "a.data-confirm", function (e) {

            // Prevent the form to be submitted


            var button = $(this); // Get the button
            var msg = button.data('confirm'); // Get the confirm message
            if(!confirm(msg))
            {
                e.preventDefault();
            }

        });


        // On click of one of these submit buttons


    }

}.init();

module.exports = app;
export default app;

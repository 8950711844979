var route = {

    /**
     * Add meter.
     */
    addMeter() {
        $('#selectall').click((event) => {  //on click
            if (event.target.checked) { // check select status
                $('.checker').each((i, el) => { //loop through each checkbox
                    el.checked = true;  //select all checkboxes with class "checkbox1"
                });
            } else {
                console.log('this is not checked');
                $('.checker').each((i, el) => { //loop through each checkbox
                    el.checked = false; //deselect all checkboxes with class "checkbox1"
                });
            }
        });
    },

    /**
     * TODO
     */
    edit() {
        console.log('route controller edit');
    },

    /**
     * TODO
     */
    resequence() {
        console.log('route controller resequence');
    }

};

module.exports = route;
export default route;

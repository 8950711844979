var billingRateGroup = {

    /**
     * TODO
     */
    create() {
        console.log('billing rate group create');
    },

    /**
     * TODO
     */
    edit() {
        console.log('billing rate group edit');
    }

};

module.exports = billingRateGroup;
export default billingRateGroup;
var dashboard = {

    home() {
        dashboard.renderAgedReceivableChart();
        dashboard.renderAverageWaterUsageChart();
        dashboard.renderPaymentsChart();
        dashboard.renderReadingChart();
        dashboard.renderMembershipChart();
        dashboard.renderMatchHeight();
    },

    renderAgedReceivableChart() {

        $.ajax({
            url:      '/resources/aged/chart',
            dataType: 'json'
        }).done(function(msg){

            new Chartist.Bar('#aged-receivables-chartist', msg, {
                height: 260,
                low: 0,
                axisY: {
                },
                axisX: {
                },
                stackBars: true,
                plugins: [
                Chartist.plugins.legend({
                    legendNames: msg.legendNames
                })
            ]
            });

        });

    },

    renderAverageWaterUsageChart() {


        $.ajax({
            url:      '/resources/readings/average',
            dataType: 'json'
        }).done(function(msg){


            var data = msg;

            var options = {
                height: 280
            };

            var responsiveOptions = [
                ['screen and (max-width: 640px)', {
                    seriesBarDistance: 5,
                    axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        }
                    }
                }]
            ];

            new Chartist.Bar('#average-water-chartist', data, options, responsiveOptions);

        });



    },

    renderPaymentsChart() {
        $.ajax({
            url:      '/resources/payments/totalbyaccounttype',
            dataType: 'json'
        }).done(msg => {
            new Chartist.Line('#payments-account-type-chartist', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: false,
                axisY: {
                },
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames
                })]
            });
        });
    },

    renderReadingChart() {
        $.ajax({
            url:      '/resources/readings/chart',
            dataType: 'json'
        }).done(function (msg) {
            new Chartist.Line('#readings-chartist', {
                labels: msg.labels,
                series:
                    msg.series
            }, {
                height: 260,
                low: 0,
                showArea: true,
                axisY: {
                }
            });
        });
    },

    renderMembershipChart() {
        $.ajax({
            url:      '/resources/accounts/membership',
            dataType: 'json'
        }).done(function (msg) {
            new Chartist.Pie('#membership-chartist', {
                series: msg.data
            }, {
                height: 220,
                width: '50%',
                donut: true,
                donutWidth: 40,
                showLabel: false,
                plugins: [
                    Chartist.plugins.legend({
                        legendNames: msg.legendNames,
                        position: 'bottom'
                    })]
            });
        });
    },

    renderMatchHeight() {
        $('.col-1-4.dash-block').matchHeight();
        $('.dash-block .title').matchHeight();
    }
};

module.exports = dashboard;
export default dashboard;